import debounce from 'lodash.debounce'

class CSizes {
  constructor() {
    this.breakpoints = {
      tablet: 768,
      desktop: 1080,
    }

    const getSizes = () => {
      this.window = {
        width: window.innerWidth,
        height: window.innerHeight,
      }

      this.isMobile = this.window.width < this.breakpoints.tablet
      this.isTablet =
        this.window.width >= this.breakpoints.tablet &&
        this.window.width < this.breakpoints.desktop
      this.isDesktop = this.window.width >= this.breakpoints.desktop
    }

    getSizes()
    window.addEventListener('resize', debounce(getSizes, 200))
  }
}

const Sizes = new CSizes()
export default Sizes
