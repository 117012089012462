export default class Navbar {
  constructor() {
    this.isFullpage = false
    this.navbar = document.querySelector('.header-main .navbar')
    this.burger = this.navbar.querySelector('.navbar-burger')
    this.menu = this.navbar.querySelector('.navbar-menu')
    this.active = false

    this.burger.addEventListener('click', () => {
      this.active = !this.active
      this.menu.classList.toggle('is-active', this.active)
      this.burger.classList.toggle('is-active', this.active)
      this.burger.setAttribute('aria-expanded', this.active)

      if (this.isFullpage)
        document.documentElement.classList.toggle('is-clipped', this.active)
    })
  }
}
