import Navbar from './components/navbar'
import Page from './components/page'
import '../scss/base.scss'

class CAPP {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      this.navbar = new Navbar()
      this.page = new Page()
    })
  }
}

export const APP = new CAPP()
